@import-normalize;

body {
  margin: 0px;
}

a {
  text-decoration: none;
}

input, input:-webkit-autofill,
input, input:-webkit-autofill:hover,
input, input:-webkit-autofill:focus,
input, input:-webkit-autofill:active {
    transition: background-color 99999999999999999s ease-in-out 0s, color 99999999999999999s;
    /* background-color: transparent !important; */
}
:focus {
  outline: none;
}
.character--selected {
  border-width: 2 !important;
  border-bottom-style: solid !important;
}

.calendar-custom-bg .MuiPickersStaticWrapper-staticWrapperRoot {
  background-color: #f6ede7 !important;
}

@media (max-width: 12450px) {

}
.float {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
@media (max-width: 600px) {
  .float {
    right: 20px;
  }
}

.my-float {
	margin-top:16px;
}